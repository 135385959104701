import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('tabs', function (conf = {}) {
    const { active = 0 } = conf
    let targetCache = null // needed for safari

    return {
      instance: null,
      active,
      getCurrentIndex() {
        const content = this.$refs.content
        const activeChild = targetCache || content.querySelector(window.location.hash)

        if (!activeChild) return false

        targetCache = null
        return [...content.children].indexOf(activeChild)
      },
      handleClick(e) {
        const target = (e.target.closest('a') || e.target.parentElement.querySelector('a')).hash
        const elem = document.querySelector(target)
        const id = elem?.id
        targetCache = elem

        if (!elem) return

        elem.removeAttribute('id')
        this.$nextTick(() => {
          elem.setAttribute('id', id)
        })
      },
      handleHash() {
        if (window.location.hash.length <= 1) return
        
        const index = this.getCurrentIndex()
        if (typeof index === 'number') this.active = index
      },
      preloadImages(e) {
        const target = (e.target.closest('a') || e.target.parentElement.querySelector('a')).hash
        const elem = document.querySelector(target)

        if (!elem) return

        ;[...elem.querySelectorAll('img[loading="lazy"]')].map(img => {
          img.loading = 'eager'
        })
      },
      init() {
        this.handleHash()
        window.addEventListener('hashchange', this.handleHash.bind(this))
      },
    }
  })
})()
