import Alpine from 'alpinejs'

export default (function () {
  const loader = () => import('lax.js').then(mod => {
    const lax = mod.default
    lax.init()
    lax.addDriver('scrollY', function () {
      return window.scrollY
    })
    return lax
  }).catch(e => console.log('Error loading lax.js', e));

  Alpine.data('parallax', (speed = 1) => ({
    instance: null,
    init() {
      const el = this.$el

      this.instance = loader()
      this.instance.then(lax => {
        lax.addElement(el, {
          scrollY: {
            translateY: [
              ['elInY', 'elOutY'],
              [0, `-screenHeight / 2 * ${speed}`],
            ]
          }
        })
      })
    }
  }))
})()
