import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('marquee', (conf, items = []) => ({
    instance: null,
    async init() {
      const [Plugin, loop] = await import('dynamic-marquee').then(mod => [mod.Marquee, mod.loop]).catch(e => console.log(e))

      this.instance = new Plugin(this.$root, conf)

      let options = {
        root: this.$root,
        rootMargin: '-5% 0%',
        threshold: [0, 0.1, 0.9, 1],
      }

      let intersectionhandler = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
            entry.target.classList.toggle('opacity-0', !(entry.isIntersecting && entry.intersectionRatio > 0.9))
          })
      }, options)

      let mutationhandler = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
          if (mutation.removedNodes) mutation.removedNodes.forEach(entry => {
            intersectionhandler.unobserve(entry)
          })
        })
      })

      const el = document.createElement('div')
      const builders = items.map(html => () => {
        el.innerHTML = html.trim()
        const out = el.firstElementChild
        intersectionhandler.observe(out)
        mutationhandler.observe(out, { childList: true })
        return out
      })

      loop(this.instance, builders)
    },
    destroy() {
      this.instance && this.instance.clear()
    },
  }))
})()
