import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('contentLoader', function (conf = {}) {
    return {
      stack: [],
      error: false,
      loading: false,
      async load(target, append = true) {
        const url = new URL(target, window.location)

        // prevent attacks
        if (!(window.location.hostname === url.hostname || !url.hostname.length)) return

        this.loading = true

        try {
          const data = await fetch(url.href)
          const html = await data.text()

          if (append) this.stack[this.stack.length] = html.trim()
          else this.stack = [html.trim()]

        } catch (error) {
          this.error = true
          console.warn(error)
        }

        this.loading = false
      },
    }
  })
})()