export default {
  modalController: null,
  modalContent: null,
  modalError: null,
  loadingModal: false,
  async openModal(target) {
    if (this.modalController) this.modalController.abort()

    const url = new URL(target, window.location)
    // prevent attacks
    if (!(window.location.hostname === url.hostname || !url.hostname.length))
      return

    this.loadingModal = true
    this.modalController = new AbortController()
    const signal = this.modalController.signal

    try {
      const data = await fetch(url.href, { signal })
      const html = await data.text()

      this.modalContent = html.trim()
      this.toggleOverflow()
    } catch (error) {
      this.modalError = true
      console.warn(error)
    }

    this.loadingModal = false
  },
  closeModal() {
    if (this.modalController) this.modalController.abort()
    this.modalContent = null
    this.loadingModal = false
    this.modalError = false
    this.toggleOverflow(true)
  },
  toggleOverflow(flag = false, responsive = false) {
    if (responsive)
      document.body.classList.toggle('max-lg:overflow-hidden', flag)
    else document.body.style.overflow = flag ? null : 'hidden'
  },
  measure($el, varname, dimension = 'offsetHeight', target = document.body) {
    const measure = () => {
      target.style.setProperty('--' + varname, $el[dimension] + 'px')
    }
    const obs = new ResizeObserver(() => measure())
    obs.observe($el)
  },
  printElement(element) {
    // Create a new window for printing
    const printWindow = window.open('', '', 'width=800,height=600')

    // Clone the entire HTML of the parent document
    const clonedDocument = document.documentElement.cloneNode(true)

    // Find the element with the ID 'content' in the cloned document
    const contentElement = clonedDocument.querySelector('#content')

    // Replace the inner HTML of the 'content' element with the selected element's HTML
    if (contentElement) {
      contentElement.innerHTML = element.outerHTML
    }

    // Write the cloned document to the print window
    printWindow.document.write(`
      <!DOCTYPE html>
      <html>
        ${clonedDocument.innerHTML}
      </html>
    `)

    // Wait for the content to load before calling print
    printWindow.document.close()
    printWindow.focus()
    printWindow.onload = function () {
      printWindow.print()
      printWindow.onafterprint = function () {
        printWindow.close() // Close the window after printing
      }
    }
  },
}
