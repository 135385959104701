import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'
import mask from '@alpinejs/mask'

import store from 'stores/global'

import 'modules/form'
import 'modules/choices'
import 'modules/slider'
import 'modules/lightbox'
import 'modules/tabs'
import 'modules/accordion'
import 'modules/contentLoader'
import 'modules/parallax'
import 'modules/marquee'
import 'modules/videoController'

Alpine.plugin(intersect)
Alpine.plugin(mask)
Alpine.store('app', store)

window.Alpine = Alpine
Alpine.start()
