import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('choices', function (conf = {}) {
    return {
      async init() {
        const [Choices] = await Promise.all([
          import('choices.js').then(module => module.default).catch(e => console.error(e)),
          import('styles/choices.scss')
        ])
        const instance = new Choices(this.$root, {
          loadingText: 'Lade...',
          noResultsText: 'Keine Ergebnisse gefunden',
          noChoicesText: 'Keine Auswahlmöglichkeiten',
          itemSelectText: 'Zum Auswählen drücken',
          uniqueItemText: 'Element bereits ausgewählt',
          customAddItemText: 'Nur gültige Werte sind erlaubt',
        })
      },
    }
  })
})()