import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('videoController', () => ({
    active: false,
    init() {
      let playback = null
      this.$watch('active', (active) => {
        this.$nextTick(() => {
          const video = this.$refs?.video
          if (active && video?.paused) playback = video?.play()
          else {
            if (playback) playback = null
            video?.pause()
          }
        })
      })
    }
  }))
})()
